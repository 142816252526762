import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API } from "../../apiwrapper";
import apiURl from "../../store/actions/api-url";
import { toast } from "react-toastify";
import { BASECONFIG } from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faFacebook,faInstagram,faTelegram,faLinkedin,faWhatsapp,faTwitter, faFacebookMessenger
} from "@fortawesome/free-brands-svg-icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

const Leaderboard = () => {
	const [campaignData, setCampaignData] = useState();
	const { authUser } = useSelector((state) => state);
	const referralUrl = BASECONFIG.FRONTEND_URL + "/ref/" + campaignData?.referralCode;

	let message = `Hello! I just joined Al Mabrook, A Shariah-compliant platform to invest in community projects. Launching soon!

Win Cash Rewards: Get up to $1,000 for every friend and family you refer.

Join me and explore the world of real-world asset tokenization to build your investment portfolio at your own pace.

Sign up here for exclusive access: ` + referralUrl;

	let shortMessage = `Hello! I just joined Al Mabrook, A Shariah-compliant platform to invest in community projects.

Earn up to $1,000 cash reward for every friend and family you refer.

Sign up here for exclusive access: ` + referralUrl;


	const twitterUrl = "https://twitter.com/intent/tweet?text=" + shortMessage ;
	const fbUrl = "https://www.facebook.com/sharer/sharer.php?u=" + referralUrl;
	const whatsappUrl = "https://api.whatsapp.com/send?text=" + message;
	const linkedinUrl = "https://www.linkedin.com/shareArticle?mini=true&url=" + referralUrl;
	const telegramUrl = "https://t.me/share/url?url=" + message;
	const instagramUrl = "https://www.instagram.com/";

	const fetchCampaign = async () => {
		try {
			let url = apiURl.getActiveCampaign;
			await API({
				base_url: BASECONFIG.BASE_URL_USER,
				url: `${url}/${authUser?.data?._id}`,
			}).then((data) => {
				console.log(data);
				if (data?.status || data?.status === "true") {
					setCampaignData(data);
				} else {
					toast.warn(data?.message);
				}
			});
		} catch (error) {
			toast.error(error);
			throw Error(error);
		}
	};
	useEffect(() => {
		fetchCampaign();
	}, []);
	return(
		<>
			<main class="main-middle-content-section  header-custom-main">
				<div class="container-fluid container-xl">
					<div class="login-input-content-sec row">
						<div class="reword-content-sec col-md-6">

							<h4>Start sharing to climb Leaderboard</h4>
							<h5>How it works</h5>
							<p>Share and be at the forefront of using our upcoming Halal Investment Super App! Refer your friends and family and get a chance to participate in our $1000 cash reward!</p>

							<div class="reword-top-list-main">
								<h5 class="pb-3">Rewards</h5>
								<ul>
									{campaignData?.data?.rewards?.map((ele) =>
										<li>
											<div class="list-main-con">
												<figure>
													<img src="Image/tropy-win.svg"/>
													<span class="reword-list-title">{ele.positionName}</span>
												</figure>

												<span class="price-sapce">$ {ele.amount}</span>
											</div>
										</li>
									)}
								</ul>
								{/*<button class="close-reword-sec-btn"> <FontAwesomeIcon icon={faArrowCircleUp} />  Close rewards</button>*/}
							</div>
							<div class="input-copy-sec-main social-all-icon mt-5 mb-5">
								<p>Copy and share this link</p>
								<input type="text" readOnly value={referralUrl}/>
								<span class="hand" onClick={() => {navigator.clipboard.writeText(referralUrl);toast.success("Link is copied to clipboard.");}}>
									<FontAwesomeIcon icon={faCopy} />
								</span>
							</div>
							<div class="section-speretor">
								<hr/> <span> or </span> <hr/>
							</div>
						</div>
						<figure class="col-md-6 mobile-none">
							<img class="custom-set" src="Image/7.png"/>
						</figure>
					</div>
				</div>

				<div class="container-fluid container-xl mt-5 pt-2  pb-5">

					<div class="social-all-icon mb-5">
						<p>Share it on social media</p>
						<ul>
							{/*<li>*/}
							{/*	<a href={instagramUrl} target="_blank"> */}
							{/*	 	<FontAwesomeIcon icon={faInstagram} />*/}
							{/*	</a>*/}
							{/*</li>*/}
							<li>
								<a href={telegramUrl} target="_blank">
									<FontAwesomeIcon icon={faTelegram} />
								</a>
							</li>
							<li>
								<a href={linkedinUrl} target="_blank">
									<FontAwesomeIcon icon={faLinkedin} />
								</a>
							</li>
							<li>
								<a href={whatsappUrl} target="_blank">
									<FontAwesomeIcon icon={faWhatsapp} />
								</a>
							</li>
							<li>
								<a href={fbUrl} target="_blank">
									<FontAwesomeIcon icon={faFacebook} />
								</a>
							</li>
							{/*<li>*/}
							{/*	<a href={fbMessengerUrl} target="_blank"> */}
							{/*	 	<FontAwesomeIcon icon={faFacebookMessenger} />*/}
							{/*	</a>*/}
							{/*</li>*/}
							<li>
								<a href={twitterUrl} target="_blank">
									<FontAwesomeIcon icon={faTwitter} />
								</a>
							</li>
						</ul>

					</div>

					<div class="reword-card-sec-main mt-5 pb-0 mb-5">

						<div class="reword-card-sec remove-margin-cus">
							{
								campaignData?.currentPosition != 1 && <span>Invite {campaignData?.referralForNextPosition} friends to reach <b>#{campaignData?.currentPosition == 0 ? 1 : campaignData?.currentPosition - 1}</b></span>
							}

							<p>Your current position</p>
							<h3>#{campaignData?.currentPosition}</h3>
						</div>
						<div class="reword-card-sec">
							<p>Your successful referrals</p>
							<h3>{campaignData?.totalUserReferrals??0}</h3>
						</div>

					</div>

					<div class="leaderboad-list-main-contianer">
						<div class="leaderboard-header-sec">
							<div class="lt-pannel-head">
								<figure>
									<img src="Image/tropy-win.svg"/>
								</figure>
								<h3>Leaderboard</h3>
							</div>
							<p>Current count of people <span class="blue-text">{campaignData?.totalUsers}</span></p>
						</div>
						<div class="leader-list-main">
							<ul>
								{campaignData?.leaderboad?.map((ele) =>
									<li>
										<div class="lt-pannel-sec">
											<b>{ele.positionNo}.</b>
											<span>
												{ele.firstName.charAt(0) + ele.lastName.charAt(0)}
											</span>
											<p>{ele.firstName + " " + ele.lastName.charAt(0) + "."}</p>
										</div>
										<div class="rt-pannel-sec">
											<span>$ {ele.amount}</span>
											<figure>
												<img src="Image/tropy-win.svg"/>
											</figure>
										</div>
									</li>
								)}
							</ul>
						</div>
					</div>
				</div>
			</main>
		</>
	)
};

export default Leaderboard;