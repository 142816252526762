/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import Select from "react-select";
import { BASECONFIG } from "../../config";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import {
    isValid,
    validateCPassWord,
    validateCountryCode,
    validateEmail,
    validateFirstName,
    validateLastName,
    validateMobile,
    validatePassWord,
    validateTnc,
    validateRequire
} from "../../validation/input-validation";
import { API } from "../../apiwrapper";
import { useGoogleLogin } from "@react-oauth/google";
import OtherLogin from "../login/other-login";
import { useDispatch, useSelector } from "react-redux";
import { SetloaderData } from "../../store/reducer";
import VerifiOTP from "../modal/verify-otp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import ResponseMessage from "../modal/response-message";
import {
    SetCountryCodes,
    SetDisableCountry
} from "../../store/reducer";

const Signup = () => {
    const { code } = useParams();
    let regexNum = /^(?=.*[0-9])/;
    let regexSmlChar = /^(?=.*[a-z])/;
    let regexUprChar = /^(?=.*[A-Z])/;
    // let regexSpclChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let regexSpclChar = /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,<>.\/?~])/;
    const dispatch = useDispatch();
    const [passwordType] = useState("password");
    const [confirmPasswordType] = useState("password");
    const [errors, setErrors] = useState({});
    const [show, setShow] = useState(false);
    const [isVerifiOTP, setIsVerifiOTP] = useState(false);

    const [user, setUser] = useState([]);
    const { fcmToken, countryCodes } = useSelector((state) => state);
    const userRoleID = process.env.REACT_APP_USER_ROLL_ID;
    const [showModal, setShowModal] = useState(false);
    const [respMessage, setRespMessage] = useState({
        title: "",
        message: "",
        status: "",
    });
    const [modalMessage, setModalMessage] = useState("");
    const [inpField, setInpField] = useState({
        firstName: "",
        lastName: "",
        mobile: "",
        countryCode: "",
        email: "",
        password: "",
        confirmpassword: "",
        roleID: "642ae2caa25d6e5933d6bbb5",
        isChecked: "",
    });
    const [loading, setLoading] = useState(false);


    const formatOptionLabel = ({ value, label, flag }) => (
        <div>
            <img
                src={flag}
                alt="Flag"
                style={{ borderRadius: "50%", height: "20px", marginRight: "10px" }}
            />
            {label}
        </div>
    );

    const navigate = useNavigate();

    const {
        firstName = "",
        lastName = "",
        mobile = "",
        countryCode = "91",
        email = "",
        password = "",
        confirmpassword = "",
        roleID = "640f32238095761c85f2bed8",
        isChecked = "",
    } = inpField;

    const handleChange = (e) => {
        if (e.target.type == "checkbox") {
            setInpField({
                ...inpField,
                [e.target.name]: e.target.checked,
            });
        } else {
            setInpField({
                ...inpField,
                [e.target.name]: e.target.value,
            });
        }
        handleValidate(e);
    };

    const handleValidate = (e) => {
        const errors1 = {};
        switch (e.target.name) {
            case "firstName":
                errors1.firstName = validateFirstName(e.target.value);
                break;
            case "lastName":
                errors1.lastName = validateLastName(e.target.value);
                break;
            case "email":
                errors1.email = validateEmail(e.target.value);
                break;
            case "mobile":
                errors1.mobile = validateMobile(e.target.value);
                break;
            case "password":
                errors1.password = validatePassWord(e.target.value);
                break;
            case "confirmpassword":
                errors1.confirmpassword = validateCPassWord(e.target.value, password);
                break;
            case "countryCode":
                errors1.countryCode = validateCountryCode(e.target.value);
                break;
            case "isChecked":
                errors1.isChecked = validateTnc(e.target.checked);
                break;
            default:
                break;
        }
        setErrors(errors1);
    };

    const validateAll = () => {
        let err1 = {};
        err1.firstName = validateFirstName(firstName);
        err1.lastName = validateLastName(lastName);
        err1.email = validateEmail(email);
        err1.mobile = validateMobile(mobile);
        err1.countryCode = validateCountryCode(countryCode);
        err1.password = validatePassWord(password);
        err1.isChecked = validateTnc(isChecked);
        err1.confirmpassword = validateCPassWord(confirmpassword, password);
        return err1;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            let err = validateAll();
            if (isValid(err)) {

                let payload = {
                    ...inpField,
                    fcmToken: fcmToken?.data?.fcmToken,
                    type: "web",
                    refCode: code
                };
                // console.log("payload", payload);
                let url = "/v1/user";
                dispatch(SetloaderData(true));
                await API({
                    base_url: BASECONFIG.BASE_URL_USER,
                    url: url,
                    method: "POST",
                    body: payload,
                    formData: false,
                }).then((data) => {
                    if (data?.status || data?.status === "true") {
                        setTimeout(() => {
                            navigate("/registerationMessage");
                        }, 1000);
                    } else {
                        toast.warn(data?.message);
                    }
                });
            } else {
                setErrors(err);
            }
            setLoading(false);
        } catch (error) {
            toast(error, { type: "error" });
        } finally {
            dispatch(SetloaderData(false));
        }
    };

    const handleGetOTP = async (e) => {
        e.preventDefault();
        let contiBTN = document.getElementById("Continue");
        try {
            let err = validateAll();
            if (isValid(err)) {
                let url = "/v1/user/otp/send";
                dispatch(SetloaderData(true));
                contiBTN.disabled = true;
                await API({
                    base_url: BASECONFIG.BASE_URL_USER,
                    url: url,
                    method: "POST",
                    body: {
                        mobileNumber: `+${countryCode}-${mobile}`,
                        email: email
                    },
                    formData: false,
                }).then((data) => {
                    if (data?.status || data?.status === true) {
                        setRespMessage({
                            title: "Create Account",
                            message: data?.message,
                            status: data?.status,
                        });
                        handleClose();
                        setTimeout(() => {
                            setShow(true);
                        }, 100);
                    } else {
                        setRespMessage({
                            title: "Create Account",
                            message: data?.message,
                            status: data?.status,
                        });
                        setShowModal(true);
                    }
                });
            } else {
                setErrors(err);
            }
        } catch (error) {
            throw error;
        } finally {
            contiBTN.disabled = false;
            dispatch(SetloaderData(false));
        }
    };

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => toast.error("Login Failed", error),
    });

    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleClose = () => {
        setShowModal(false);
        setShow(false);
        setRespMessage({
            title: "",
            message: "",
            status: "",
        });
    };

    const fetchCountryCodes = async () => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_BACKENDURL_USER + "/v1/common/countryCode"
            );
            dispatch(SetCountryCodes(response.data.data));
            let disableCountry = response?.data?.data?.filter((ele) => !ele?.status);
            dispatch(SetDisableCountry(disableCountry));
        } catch (error) {
            console.log("Failed to fetch country codes:", error);
        }
    };

    useEffect(() => {
        fetchCountryCodes();
    }, []);

    return (
        <>
            <main class="main-middle-content-section header-custom-main">
                {show && (
                    <VerifiOTP
                        show={show}
                        handleClose={() => setShow(false)}
                        countryCode={countryCode}
                        mobileNumber={mobile}
                        setIsVerifiMob={setIsVerifiOTP}
                        fetchUserData={(e) => handleSubmit(e)}
                    />
                )}

                {showModal ? (
                    <ResponseMessage
                        show={showModal}
                        handleClose={() => handleClose()}
                        title={respMessage.title}
                        message={respMessage.message}
                        status={respMessage.status}
                    />
                ) : (
                    ""
                )}

                <div class="container-fluid container-xl">
                    <div class="login-input-content-sec row">
                        <div class="login-content-sec col-md-6">
                            <h4>Create Account</h4>
                            <p>Enter your details below.</p>
                            <div class="input-section-main">
                                <input
                                    name="email"
                                    type="email"
                                    value={email}
                                    placeholder="Email Address"
                                    className="form-control"
                                    onBlur={handleValidate}
                                    onChange={handleChange}
                                />
                                <span className="text-danger">{errors.email}</span>
                            </div>
                            <div class="input-section-main">
                                <input
                                    type={passwordType}
                                    placeholder="Password"
                                    className="form-control"
                                    onBlur={handleValidate}
                                    onChange={handleChange}
                                    value={password}
                                    name="password"
                                    onKeyPress={(event) => {
                                        if (event.key === " ") {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                                {errors.password ? (
                                    <>
                                        {" "}
                                        <span className="text-danger" style={{ fontSize: "14px" }}>
                                            {errors.password}
                                        </span>
                                        <ul>
                                            <li
                                                className={
                                                    password.length >= 8 ? "text-success" : "text-danger"
                                                }
                                                style={{ fontSize: "14px" }}
                                            >
                                                {password.length >= 8 && (
                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                )}
                                                {" "} 8 characters
                                            </li>

                                            <li
                                                className={
                                                    regexUprChar.test(password)
                                                        ? "text-success"
                                                        : "text-danger"
                                                }
                                                style={{ fontSize: "14px" }}
                                            >
                                                {regexUprChar.test(password) && (
                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                )}{" "}
                                                1 Uppercase{" "}
                                            </li>
                                            <li
                                                className={
                                                    regexSmlChar.test(password)
                                                        ? "text-success"
                                                        : "text-danger"
                                                }
                                                style={{ fontSize: "14px" }}
                                            >
                                                {regexSmlChar.test(password) && (
                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                )}{" "}
                                                1 Lowercase{" "}
                                            </li>
                                            <li
                                                className={
                                                    regexNum.test(password)
                                                        ? "text-success"
                                                        : "text-danger"
                                                }
                                                style={{ fontSize: "14px" }}
                                            >
                                                {regexNum.test(password) && (
                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                )}{" "}
                                                1 Number{" "}
                                            </li>
                                            <li
                                                className={
                                                    regexSpclChar.test(password)
                                                        ? "text-success"
                                                        : "text-danger"
                                                }
                                                style={{ fontSize: "14px" }}
                                            >
                                                {regexSpclChar.test(password) && (
                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                )}{" "}
                                                1 Special character
                                            </li>
                                        </ul>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>

                            <div class="input-section-main">
                                <input
                                    type={confirmPasswordType}
                                    placeholder="Confirm Password"
                                    className="form-control"
                                    onBlur={handleValidate}
                                    onChange={handleChange}
                                    name="confirmpassword"
                                    value={confirmpassword}
                                    onKeyPress={(event) => {
                                        if (event.key === " ") {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                                <span className="text-danger">{errors.confirmpassword}</span>
                            </div>

                            <div class="input-section-main">
                                <input
                                    type="text"
                                    placeholder="Enter the First Name"
                                    className="form-control"
                                    name="firstName"
                                    value={firstName}
                                    onBlur={handleValidate}
                                    onChange={handleChange}
                                    maxlength="30"
                                />
                                <span className="text-danger">{errors.firstName}</span>
                            </div>

                            <div class="input-section-main">
                                <input
                                    type="text"
                                    placeholder="Enter the Last Name"
                                    className="form-control"
                                    name="lastName"
                                    onBlur={handleValidate}
                                    value={lastName}
                                    onChange={handleChange}
                                    maxlength="30"
                                />
                                <span className="text-danger">{errors.lastName}</span>
                            </div>
                            <div class="input-section-main">
                                <div><Select
                                    placeholder="Select Country Code "
                                    options={
                                        countryCodes?.data?.length
                                            ? countryCodes?.data?.map((code) => ({
                                                value: code?.phone?.countryCode || "",
                                                label:
                                                    `${code?.info?.alpha3} +${code?.phone?.countryCode}` ||
                                                    "",
                                                flag: code?.info?.flag || "",
                                            }))
                                            : []
                                    }
                                    name="countryCode"
                                    formatOptionLabel={formatOptionLabel}
                                    classNamePrefix="countryCode"
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    onBlur={handleValidate}
                                    onChange={(option) =>
                                        setInpField({
                                            ...inpField,
                                            countryCode: option.value,
                                        })
                                    }
                                />
                                    <span className="text-danger">{errors.countryCode}</span>
                                </div>

                            </div>
                            <div class="input-section-main">
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Enter the Mobile"
                                        className="input[type=number]::-webkit-outer-spin-button form-control"
                                        name="mobile"
                                        value={mobile}
                                        onBlur={handleValidate}
                                        onChange={(e) => {
                                            const re = /^[0-9]{0,10}$/;
                                            const inputValue = e.target.value;
                                            if (inputValue === "" || re.test(inputValue)) {
                                                if (e.target.value.includes(" ")) {
                                                    e.target.value = inputValue.replace(" ", "");
                                                }
                                                handleChange(e);
                                            }
                                        }}
                                        onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                                    />

                                    <span className="text-danger">{errors.mobile}</span>
                                </div>
                            </div>
                            <div class="input-section-main keep-sign-text">
                                <label><input
                                    type="checkbox"
                                    id="isChecked"
                                    name="isChecked"
                                    value={isChecked}
                                    onClick={handleChange}
                                    className="checkBoxCustome"
                                /> <span>{" "}
                                        I have read and agree to
                                        <Link className="blue-text" to={"/privacyPolicy"} target="_blank">
                                            {" "}
                                            Mabrook Privacy Policy
                                        </Link>{" "}
                                        and{" "}
                                        <Link className="blue-text" to={"/termsOfUse"} target="_blank">
                                            {" "}
                                            Terms of use
                                        </Link></span></label>
                                <span className="text-danger">{errors.isChecked}</span>
                            </div>

                            <button
                                className="login-page-btn"
                                onClick={handleGetOTP}
                                disabled={loading}
                                id="Continue"
                            >
                                {loading ? "Please Wait..." : "Continue"}
                            </button>
                            <div class="section-speretor">
                                <hr /> <span> or </span> <hr />
                            </div>

                            <OtherLogin isSignup={true} code={code} />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                        <figure class="col-md-6 rt-img-login-sec">
                            <img src="../Image/signup.png" />
                        </figure>
                    </div>
                </div>
            </main>

        </>
    );
};

export default Signup;
