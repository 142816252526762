/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import "bootstrap/dist/css/bootstrap.min.css"
import "./App.css";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Layout from "./components/layout";
import Login from "./components/login";
import Signup from "./components/signup";
import SignupCompleted from "./components/signup/signup-completed";
import RecoverPassword from "./components/login/recover-password";
import Privacypolicy from "./components/privacypolicy";
import TermCondition from "./components/termscondition";
import TermOfUse from "./components/termsofuse";
import Homepage from "./components/pages/homepage.js";
import Mailverification from "./components/emailverification";
import LoaderComponent from "./components/loader";
import apiURl from "./store/actions/api-url";
import {
    SetloaderData
} from "./store/reducer";
import {
    SetAuthUserData,
} from "./store/reducer/auth-user";
import { initializeApp } from "firebase/app";
import ChangePassword from "./components/login/change-password";
import Leaderboard from "./components/leaderboard";
import { SetPasswordToken } from "./store/reducer/password-token";
import { getToken, getMessaging } from "firebase/messaging";
import { setFcmToken } from "./store/reducer/fcm-token";
import { BASECONFIG } from "./config";
import { API } from "./apiwrapper";
import VerifyPhone from "./components/signup/verify-phone";


function App() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userToken = localStorage.getItem("token");
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    const [firebaseToken, setFirebaseToken] = useState();
    var tokenRequestSent = false;

    
    dispatch(SetPasswordToken({ token: code }));
    const sendLinkedInCode = async () => {
        tokenRequestSent = true;
        dispatch(SetloaderData(true));
        try {
            const url = apiURl.getToken;
            const referralCode = localStorage.getItem("referralCode");
            const payload = {
                token: code,
                fcmToken: firebaseToken,
                type: "web",
                refCode: referralCode
            };
            const data = await API({
                base_url: BASECONFIG.BASE_URL_USER,
                url: url,
                method: "POST",
                body: payload,
                formData: false,
            });
            console.log(data);
            if (data?.status || data?.status === "true") {
                localStorage.removeItem("referralCode");
                if (!data?.data.mobile_verified) {
                    dispatch(SetAuthUserData(data?.data));
                    localStorage.setItem("token", data?.data?.token);
                    const redirectPath = `/verifyPhone`;
                    localStorage.removeItem("redirectPath");
                    navigate(redirectPath);
                }
                else {
                    dispatch(SetAuthUserData(data?.data));
                    localStorage.setItem("token", data?.data?.token);
                    localStorage.removeItem("redirectPath");
                    navigate("/leaderboard");
                }
            } else {
                toast.error(data?.message);
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(SetloaderData(false));
        }
    };

    useEffect(() => {
        if (code && !tokenRequestSent) {
            sendLinkedInCode();
        }
        return () => {
            dispatch(SetloaderData(false));
        };
    }, []);

    const firebaseConfig = {
        apiKey: "AIzaSyC497xVZKoec3bZtNW-HXPDbr3imCDRt-4",
        authDomain: "almabrook-add80.firebaseapp.com",
        projectId: "almabrook-add80",
        storageBucket: "almabrook-add80.appspot.com",
        messagingSenderId: "1055471595107",
        appId: "1:1055471595107:web:2bb7f32488144fab7b9256",
        measurementId: "G-Z8DDZSHZXL",
    };
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging();
    getToken(messaging, {
        vapidKey:
            "BGLJnynuJB6mouJZPyXrqJQW6TRZLP5ScypOQ5JyWty6hBymtzHir397rabR1iOxR6Q6h9OgG-f3xGXCt2iqa4M",
    })
        .then((currentToken) => {
            if (currentToken) {
                setFirebaseToken(currentToken);
                dispatch(setFcmToken({ fcmToken: currentToken }));
            } else {
            }
        })
        .catch((err) => {
            // ...
        });


    return (
        <>
            <LoaderComponent />
            <div className="App">
                <Routes>
                    <Route element={<Layout />}>
                        <Route path="/" element={<Homepage />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Signup />} />
                        <Route path="/verifyPhone" element={<VerifyPhone />} />
                        <Route path="/registerationMessage" element={<SignupCompleted />} />
                        <Route path="/privacyPolicy" element={<Privacypolicy />} />
                        <Route path="/termsCondition" element={<TermCondition />} />
                        <Route path="/mailverification" element={<Mailverification />} />
                        <Route path="/recoverPassword" element={<RecoverPassword />} />
                        <Route path="/changePassword" element={<ChangePassword />} />
                        <Route path="/leaderboard" element={<Leaderboard />} authorized={userToken ? true : false} />
                        <Route path="/ref/:code" element={<Signup />} />
                        <Route path="/termsOfUse" element={<TermOfUse />} />
                    </Route>
                </Routes>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={100000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
            />
        </>
    );
}

export default App;
