
const TermCondition = () => {
  return (
    <>
    <section id="teamBanner" className="pb-5 header-custom-main">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5">
              <div className="teamBanner1">
                <h4>Terms & Conditions</h4>
                <p>How would you like to contact us?</p>
              </div>
            </div>
            <div className="col-md-6">
              {/* <img src="Image/Familypicture2.png" alt="" className="img-fluid" /> */}
            </div>
          </div>
        </div>
      </section>
      <section className="termsBnanerSection header-custom-main">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="termsConditionsheading">Rules and Regulations</h1>
              {/* <h4 className="welcomeAlM mt-2">Welcome to Al Mabrook!</h4> */}
              <p className="paratermsCondition mt-5">
                These terms & conditions outline the rules and regulations for
                the use of this Website, located at <a href="Mabrook.io">Mabrook.io</a>.
              </p>
              <p className="paratermsCondition mt-3">
                By using this website, we presume that you agree to these terms
                & conditions. If you do not agree to all of the terms &
                conditions listed on this page, do not proceed to use Mabrook.
              </p>
              <p className="paratermsCondition mt-3">
                The following terminology applies to these Terms & Conditions,
                Privacy Statement and Disclaimer Notice and all Agreements:
                "Client", "You" and "Your" refers to you, the person logged on
                to this website and compliant to the Company's terms &
                conditions. "The Company", "Ourselves", "We", "Our" and "Us",
                refers to our Company. "Party", "Parties", or "Us", refers to
                both the Client and ourselves.
              </p>
              <p className="paratermsCondition mt-3">
                Any use of the above terminology or other words in the singular,
                plural, capitalization and/or he/she or they, are taken as
                interchangeable and therefore as referring to same.
              </p>
              <h5 className="cookies mt-5">Cookies</h5>
              <p className="paratermsCondition mt-3">
                We employ the use of cookies. By accessing Mabrook, you agreed
                to use cookies in agreement with the Mabrook's Privacy Policy.
              </p>
              <p className="paratermsCondition mt-3">
                Most interactive websites use cookies to let us retrieve the
                user's details for each visit. Cookies are used by our website
                to enhance the functionality of our website, making it easier
                for people visiting and using our website. This data is not
                mined, shared, or otherwise collected for commercial use. You
                are free to delete these cookies from within your web browser.
              </p>
              <h5 className="licence mt-5">Licence</h5>
              <p className="paratermsCondition mt-3">
                Unless otherwise stated, Mabrook and/or its licensors own the
                intellectual property rights for all material on Mabrook. All
                intellectual property rights are reserved. You may access this
                from Mabrook for your own personal use subjected to restrictions
                set in the following terms & conditions.
              </p>
              <p className="paratermsCondition mt-3">You must not:</p>
              <ul className="termsCondtion">
                <li className="termsCondtions">
                  Republish material from Mabrook
                </li>
                <li className="termsCondtions">
                  Sell, rent or sub-license material from Mabrook
                </li>
                <li className="termsCondtions">
                  Reproduce, duplicate or copy material from Mabrook
                </li>
                <li className="termsCondtions">
                  Redistribute content from Mabrook
                </li>
              </ul>

              <h5 className="hyperLink mt-5">Hyperlinking to our Content</h5>
              <p className="paratermsCondition mt-3">
                The following organisations may link to our website without
                prior written approval:
              </p>
              <ul className="termsCondtion">
                <li className="termsCondtions">Government agencies;</li>
                <li className="termsCondtions">Search engines;</li>
                <li className="termsCondtions">News organisations;</li>
                <li className="termsCondtions">
                  Online directory distributors may link to our website in the
                  same manner as they hyperlink to the Websites of other listed
                  businesses; and
                </li>
                <li className="termsCondtions">
                  System wide Accredited Businesses except soliciting non-profit
                  organisations, charity shopping malls, and charity fundraising
                  groups which may not hyperlink to our Web site.
                </li>
              </ul>
              <p className="paratermsCondition mt-3">
                These organisations may link to our homepage, to publications or
                to other Website information so long as the link: (a) is not in
                any way deceptive; (b) does not falsely imply sponsorship,
                endorsement or approval of the linking party and its products
                and/or services; and (c) fits within the context of the linking
                party's site.
              </p>

              <p className="paratermsCondition mt-5">
                We may consider and approve other link requests from the
                following types of organisations:
              </p>
              <ul className="termsCondtion">
                <li className="termsCondtions">
                  commonly-known consumer and/or business information sources;
                </li>
                <li className="termsCondtions">dot.com community sites;</li>
                <li className="termsCondtions">
                  associations or other groups representing charities;
                </li>
                <li className="termsCondtions">
                  online directory distributors;
                </li>
                <li className="termsCondtions">internet portals;</li>
                <li className="termsCondtions">
                  accounting, law and consulting firms; and
                </li>
                <li className="termsCondtions">
                  educational institutions and trade associations.
                </li>
              </ul>

              <p className="paratermsCondition mt-3">
                We will approve link requests from these organisations if we
                decide that: (a) the link would not harm our reputation or those
                of our accredited businesses.; (b) the organisation does not
                have any negative records with us; (c) the benefit to us from
                the visibility of the hyperlink compensates the absence of
                Mabrook; and (d) the link is in the context of general resource
                information.
              </p>

              <p className="paratermsCondition mt-3">
                These organizations may link to our home page so long as the
                link: (a) is not in any way deceptive; (b) does not falsely
                imply sponsorship, endorsement or approval of the linking party
                and its products or services; and (c) fits within the context of
                the linking party's site.
              </p>
              <p className="paratermsCondition mt-3">
                If you are one of the organizations listed in paragraph 2 above
                and are interested in linking to our website, you must inform us
                by sending an e-mail. Please include your name, your
                organization's name, contact information as well as the URL of
                your site, a list of any URLs from which you intend to link to
                our Website, and a list of the URLs on our site to which you
                would like to link. Expect a response within 2 to 3 weeks.
              </p>

              <p className="paratermsCondition mt-3">
                Approved organizations may hyperlink to our Website as follows:
              </p>
              <ul className="termsCondtion">
                <li className="termsCondtions">
                  By use of our corporate name; or
                </li>
                <li className="termsCondtions">
                  By use of the uniform resource locator being linked to; or
                </li>
                <li className="termsCondtions">
                  By use of any other description of our Website being linked to
                  that makes sense within the context and format of content on
                  the linking party's site.
                </li>
              </ul>
              <p className="paratermsCondition mt-3">
                No use of Mabrook's logo or other artwork will be allowed for
                linking in absence of a trademark license agreement.
              </p>
              <h5 className="cookies mt-5">iFrames</h5>
              <p className="paratermsCondition mt-3">
                Without prior approval and written permission, you may not
                create frames around our Webpages that in any way alter the
                visual presentation or appearance of our website.
              </p>
              <h5 className="cookies mt-5">Disclaimer</h5>
              <p className="paratermsCondition mt-3">
                To the maximum extent permitted by applicable law, we exclude
                all representations, warranties and conditions relating to our
                website and the use of this website. Nothing in this disclaimer
                will:
              </p>
              <ul className="termsCondtion">
                <li className="termsCondtions">
                  limit or exclude our or your liability for death or personal
                  injury;
                </li>
                <li className="termsCondtions">
                  limit or exclude our or your liability for fraud or fraudulent
                  misrepresentation;
                </li>
                <li className="termsCondtions">
                  limit any of our or your liabilities in any way that is not
                  permitted under applicable law; or
                </li>
                <li className="termsCondtions">
                  exclude any of our or your liabilities that may not be
                  excluded under applicable law.
                </li>
              </ul>
              <p className="paratermsCondition mt-4">
                The limitations and prohibitions of liability set in this
                Section and elsewhere in this disclaimer: (a) are subject to the
                preceding paragraph; and (b) govern all liabilities arising
                under the disclaimer, including liabilities arising in contract,
                in tort and for breach of statutory duty.
              </p>
              <p className="paratermsCondition mt-3">
                As long as the website and the information and services on the
                website are provided free of charge, we will not be liable for
                any loss or damage of any nature.
              </p>
              <p className="paratermsCondition mt-3">
                Buying and trading cryptocurrencies, tokens and financial
                investment should be considered a high-risk activity. We
                encourage you to consult with a registered investment adviser or
                qualified investment professional and to do your own due
                diligence before making any investment decisions. Mabrook is not
                accountable, directly or indirectly, for any damage or loss
                incurred, alleged or otherwise, in connection to the use or
                reliance of any content you read on the site.
              </p>
              <br/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermCondition;
