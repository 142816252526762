/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "../../apiwrapper";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import apiURl from "../../store/actions/api-url";
import { toast } from "react-toastify";
import { SetAuthUserData } from "../../store/reducer/auth-user";
import { BASECONFIG } from "../../config";

const OtherLogin = ({ isSignup = false, code = "" }) => {
    const [profile, setProfile] = useState([]);
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const dispatch = useDispatch();
    const { fcmToken } = useSelector((state) => state);

    const location = useLocation();

    if (!isSignup && location.pathname !== "/login") {
        localStorage.setItem("redirectPath", location.pathname);
    }

    const login = useGoogleLogin({
        onSuccess: (tokenResponse) => setUser(tokenResponse),
    });

    // const handleLinkedInSignup = (e) => {
    //   e.preventDefault();
    //   window.location.href = `${BASECONFIG.BASE_URL_USER}/auth/linkedin`;
    // };

    const handleLinkedInSignup = (e) => {
        e.preventDefault();

        const currentPath = window.location.pathname;
        if (!isSignup && currentPath !== "/login") {
            localStorage.setItem("redirectPath", currentPath);
        }
        if (isSignup && currentPath !== "/login" && currentPath !== "/register") {
            localStorage.removeItem("referralCode");
            localStorage.setItem("referralCode", code);
        }
        window.location.href = `${BASECONFIG.BASE_URL_USER}/auth/linkedin`;
    };

    const fetchData = async () => {
        try {
            const userInfoRes = await axios.get(
                `https://openidconnect.googleapis.com/v1/userinfo?access_token=${user.access_token}`,
                {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: "application/json",
                    },
                }
            );
            const obj = userInfoRes?.data;

            setProfile(obj);

            const url = apiURl.createLogin;
            const payload = {
                name: obj?.name,
                family_name: obj?.family_name,
                given_name: obj?.given_name,
                email: obj?.email,
                picture: obj?.picture,
                sub: obj?.sub,
                email_verified: obj?.email_verified,
                fcmToken: fcmToken?.data?.fcmToken,
                type: "web",
                isSignup: isSignup,
                refCode: code
            };

            const data = await API({
                base_url: BASECONFIG.BASE_URL_USER,
                url: url,
                method: "POST",
                body: payload,
                formData: false,
            });
            console.log(data);
            console.log(isSignup);
            if (data?.status || data?.status === "true") {
                if (!data?.data.mobile_verified) {
                    dispatch(SetAuthUserData(data?.data));
                    localStorage.setItem("token", data?.data?.token);
                    const redirectPath = `/verifyPhone`;
                    localStorage.removeItem("redirectPath");
                    navigate(redirectPath);
                }
                else {
                    dispatch(SetAuthUserData(data?.data));
                    localStorage.setItem("token", data?.data?.token);
                    //const redirectPath = localStorage.getItem("redirectPath") || "/";
                    const redirectPath = "/leaderboard";
                    navigate(redirectPath);
                    localStorage.removeItem("redirectPath");
                }
            } else {
                toast.error(data?.message);
            }
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        if (user) {
            fetchData();
        }
    }, [user]);

    const logOut = () => {
        googleLogout();
        setProfile(null);
    };

    return (
        <div class="social-media-btn">
            <button class="google-scoial-btn" onClick={() => login()}><img src="../Image/google-icon.png" /> <span>Google</span></button>
            <button class="linkedln-scoial-btn" onClick={handleLinkedInSignup}><img src="../Image/linkedIn-icon.png" /> <span class="pt-2">Linkedln</span></button>
        </div>
    );
};

export default OtherLogin;
