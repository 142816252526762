
const TermOfUse = () => {
  return (
    <>
    <section id="teamBanner" className="pb-5 header-custom-main">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5">
              <div className="teamBanner1">
                <h4>Terms of Use</h4>
                {/*<p>How would you like to contact us?</p>*/}
              </div>
            </div>
            <div className="col-md-6">
            </div>
          </div>
        </div>
      </section>
      <section className="termsBnanerSection header-custom-main">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
                <ol className="termsCondtion pt-5">
                    <li className="termsCondtions"><b>Open to All:</b> Our referral program welcomes everyone to participate. Whether you're a potential investor, an excited individual wanting to use our platform, or someone keen on spreading the word, you're eligible to join and refer others. Mabrook employees are not allowed to participate in the referral program.</li>
                    <li className="termsCondtions"><b>Personalised Referral Link:</b> Once you're part of the program, you'll receive a unique referral link. Share this link across your social networks, through emails, or in conversations to refer friends, family, and acquaintances.</li>
                    <li className="termsCondtions"><b>Sharing Made Easy:</b> Feel free to spread the word through various channels – from social media platforms to personal recommendations. Your unique endorsement brings authenticity to your referrals.</li>
                    <li className="termsCondtions"><b>Reliable Tracking:</b> Our advanced system ensures that each referral is tracked back to you accurately. You can trust that your efforts are properly recognized.</li>
                    <li className="termsCondtions"><b>Top Achiever Incentives:</b> While rewards aren't earned with every successful referral, those who secure a place among the top 5 will enjoy the benefits of their outstanding performance.
                        <br />
                        <span className="mb-1">🥇 1st Place: $400</span>
                        <br />
                        <span className="pb-1">🥈 2nd Place: $250</span>
                        <br />
                        <span className="pb-1">🥉 3rd Place: $200</span>
                        <br />
                        <span className="pb-1">🏅 4th Place: $100</span>
                        <br />
                        <span className="pb-1">🏅 5th Place: $50</span>
                     </li>
                    <li className="termsCondtions"><b>Reward Redemption:</b> Redeeming your rewards is straightforward. We've simplified the process, you will receive your cash reward directly to your bank account.</li>
                    <li className="termsCondtions"><b>Incentive for All:</b> If you refer any family or friends to Mabrook and in the future they invest in a Tokenized project, you'll receive at least $10.00 in rewards. The incentive amount may change based on the nature of the project and Shariah review.</li>
                    <li className="termsCondtions"><b>Validity Period:</b> Referral links come with an allotted validity period. The program will run for 30 days.</li>
                    <li className="termsCondtions"><b>Honesty and Transparency:</b> Our program thrives on honesty and transparency. You can effortlessly track your referrals, ensuring a clear understanding of your contributions.
                        <ol type="a" className="pt-2 pb-2">
                            <li><b>Unique User:</b> The uniqueness of a user in the referral program is established through a combination of their unique email and phone number, as confirmed during the sign-up process. This ensures that each user is distinct within the system, preventing duplication and providing an accurate count of individual participants.</li>
                            <li><b>Personalised Referral Link:</b> Each participant receives a unique referral link that is tied to their account. This link is your key to tracking referrals accurately.</li>
                            <li><b>Dashboard Access:</b> You'll have access to a personalised dashboard within your account. This dashboard provides real-time updates on the number of successful referrals you've made.</li>
                            <li><b>Leaderboard Transparency:</b> If you're among the top performers, your position on the leaderboard, along with the corresponding rewards, will be clearly visible to you and others in the community.</li>
                            <li><b>Support Team Assistance:</b> Our dedicated support team is available to assist you with any questions or concerns related to tracking your referrals. You can reach out to us through social media platforms like Telegram and WhatsApp at +1(437) 553 7017.</li>
                        </ol>
                    </li>
                                  
                    <li className="termsCondtions"><b>Stay Updated:</b> We're committed to keeping you in the loop about any program updates. Be assured that any modifications or improvements will be communicated clearly.</li>
                    <li className="termsCondtions"><b>Security Measures:</b> Safeguarding against fraud and maintaining security is our priority. Any attempts at fraudulent activities will lead to disqualification and possible legal action. In the unlikely event of a disagreement, we've put in place a process to address and resolve any concerns swiftly.</li>
                    <li className="termsCondtions"><b>Privacy Protection:</b> Your privacy is paramount. Any data collected during the referral process will be managed in line with our privacy policy and applicable regulations.</li>
                    <li className="termsCondtions"><b>Seek Assistance:</b> Our dedicated team is here to assist if you have any questions or require support. Don't hesitate to reach out on our social media platforms (Telegram and WhatsApp: +1(437) 553 7017) for help or <a href="mailto:support@almabrook.io">support@almabrook.io</a> for clarifications. Our team will reply within 12 hours.</li>
                    <li className="termsCondtions"><b>Adherence to Laws:</b> All participants are expected to adhere to relevant laws and regulations while promoting the referral program.</li>
                    <li className="termsCondtions"><b>Source of Reward:</b> The reward is paid out of the Marketing budget of Mabrook, all accounting will be reported to the Shariah board of Mabrook.</li>
                    <li className="termsCondtions"><b>Shariah Compliance:</b> The referral program as a whole is reviewed and approved by Mabrook’s shariah board and a copy of the approval is published on the website. </li>
                </ol>
              <p className="paratermsCondition mt-3">
                  Joining our referral program isn't just about earning rewards – it's also about contributing to the growth of a thriving community. Your involvement is highly valued, and we're excited to have you as part of the Al Mabrook.
              </p>
              <br/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermOfUse;
