/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
import { validationMessages } from "../store/actions/api-url";

export const validateFirstName = (fName) => {
  // console.log(fName.length);
  const errMsg = !fName
    ? validationMessages.fName
    : !/^[a-z][a-z\s]*$/i.test(fName)
    ? validationMessages.allowAlphabets
    : "";
  return errMsg;
};

export const validateLastName = (lName) => {
  const errMsg = !lName
    ? validationMessages.lName
    : !/^[a-z][a-z\s]*$/i.test(lName)
    ? validationMessages.allowAlphabets
    : "";
  return errMsg;
};

export const validateEmail = (Email) => {
  const errMsg = !Email
    ? validationMessages.emailReq
    : !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email)
    ? validationMessages.validEmail
    : "";
  return errMsg;
};

export const validateMobile = (Mobile) => {
  const errMsg = !Mobile
    ? validationMessages.phoneReq
    : // ? validationMessages.phoneReq
    /\D/g.test(Mobile)
    ? // : !/^(\+\d{1,3}[- ]?)?\d{10}$/.test(Mobile)
      validationMessages.validMobile
    : Mobile.length != 10
    ? // || Mobile.length < 8
      validationMessages.validMobile
    : "";
  return errMsg;
};

export const validatePassWord = (password) => {
  const errMsg = !password
    ? validationMessages.passwReq
    : !/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,32}$/.test(
        password
      )
    ? validationMessages.validPass
    : "";
  return errMsg;
};

export const validateCPassWord = (cpassword, password) => {
  const errMsg = !cpassword
    ? validationMessages.cpasswReq
    : cpassword !== password
    ? validationMessages.notMatchPassw
    : "";
  return errMsg;
};

export const reqLoginPassWord = (password) => {
  const errMsg = !password ? validationMessages.passwReq : "";
  return errMsg;
};

export const validateTnc = (val) => {
  const errMsg = !val ? validationMessages.require : "";
  return errMsg;
};

export const isValid = (errors) => {
  let keys = Object.keys(errors);
  let countError = keys.reduce(
    (acc, curr) => (errors[curr] ? acc + 1 : acc),
    0
  );
  return countError === 0;
};

export const validateRequire = (val) => {
  const errMsg = !val ? validationMessages.require : "";
  return errMsg;
};

export const validateCountryCode = (countryCode) => {
  const errMsg = !countryCode ? validationMessages.countryCode : "";
  return errMsg;
};

export const validateOTP = (otp) => {
  // console.log(otp);
  const errMsg = !otp
    ? validationMessages.otpEnter
    : otp && otp.length < 6
    ? validationMessages.validOtp
    : "";
  return errMsg;
};