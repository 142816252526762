/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { validateOTP, isValid } from "../../validation/input-validation";
import { Button } from "react-bootstrap";
import { BASECONFIG } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../apiwrapper";
import { toast } from "react-toastify";
import { SetloaderData } from "../../store/reducer";

function VerifiOTP({
  countryCode,
  mobileNumber,
  show,
  handleClose,
  setIsVerifiMob,
  IsEnable = false,
  type = "",
  fetchUserData = () => {},
}) {
  const auth = useSelector((state) => state.authUser);
  const dispatch = useDispatch();
  const [value, setValue] = useState({
    num1: "",
    num2: "",
    num3: "",
    num4: "",
    num5: "",
    num6: "",
  });
  const [apiErrors, setApiErrors] = useState({ message: "" });
  const [seconds, setSeconds] = useState(30);
  const {
    num1 = "",
    num2 = "",
    num3 = "",
    num4 = "",
    num5 = "",
    num6 = "",
  } = value;
  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    setApiErrors({ message: "" });
  };
  const handleInput = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value.replace(/[^0-9]/g, ""),
    });
  };
  const inputfocus = (e) => {
    if (e.key === "Delete" || e.key === "Backspace") {
      const next = e.target.tabIndex - 2;
      if (next > -1) {
        e.target.form.elements[next].focus();
      }
    } else {
      const next = e.target.tabIndex;
      if (next < 6) {
        e.target.form.elements[next].focus();
      }
    }
  };
  const validateAll = () => {
    let err1 = {};
    err1.message = validateOTP(`${num1}${num2}${num3}${num4}${num5}${num6}`);
    return err1;
  };

  const handleResendOTP = async (e) => {
    e.preventDefault();
    setSeconds(30);
    if (!mobileNumber) {
      toast.error("Mobile number is required.");
      return;
    }
    try {
      let url = "/v1/user/otp/send";
      await API({
        base_url: BASECONFIG.BASE_URL_USER,
        url: url,
        method: "POST",
        body: {
          mobileNumber: `+${countryCode}-${mobileNumber}`,
        },
        formData: false,
      }).then((data) => {
        if (data?.status || data?.status === true) {
          toast.success(data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(data?.message);
        }
      });
    } catch (error) {
      toast(error, { type: "error" });
    }
  };

  const handleSubmitOtp = async (e) => {
    e.preventDefault();
    try {
      let err = validateAll();
      if (isValid(err)) {
        dispatch(SetloaderData(true));
        const payload = {
          mobileNumber: `+${countryCode}-${mobileNumber}`,
          otp: `${num1}${num2}${num3}${num4}${num5}${num6}`,
        };
        if (type === "2FA") {
          payload.IsEnable = !IsEnable;
        }
        let url = auth?.data?._id
          ? `/v1/user/otp/verify/${auth?.data?._id}`
          : `/v1/user/otp/verify`;
        await API({
          base_url: BASECONFIG.BASE_URL_USER,
          url: url,
          method: "POST",
          body: {
            ...payload,
          },
          formData: false,
        }).then((data) => {
          // console.log("otp verify", data);
          if (data?.status || data?.status === true) {
            setIsVerifiMob(data?.status || true);
            setApiErrors({ message: "" });
            toast.success(data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchUserData(e);
            setTimeout(() => handleClose(), 100);
          } else {
            setApiErrors({ message: data?.message });
          }
        });
      } else {
        setApiErrors(err);
      }
    } catch (error) {
      throw error;
    } finally {
      dispatch(SetloaderData(false));
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Enter Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="twoFactor">
            <p className="text-center">
              {`Enter the 6-digit code that we sent to +${countryCode}-${mobileNumber} to verify
                  phone number.`}
            </p>
            <div className="row pt-0">
              <form className="form" action="" method="post">
                <div className="Verify-otp-div mb-0">
                  <ul className="otpList">
                    <li>
                      <input
                        type="number"
                        name="num1"
                        value={num1}
                        maxLength={1}
                        onInput={handleInput}
                        onChange={handleChange}
                        tabIndex="1"
                        onKeyUp={inputfocus}
                        // onKeyPress={keyPressed}
                        className="form-control"
                      />
                    </li>
                    <li>
                      <input
                        type="number"
                        name="num2"
                        value={num2}
                        maxLength={1}
                        onInput={handleInput}
                        onChange={handleChange}
                        tabIndex="2"
                        onKeyUp={inputfocus}
                        // onKeyPress={keyPressed}
                        className="form-control"
                      />
                    </li>
                    <li>
                      <input
                        type="number"
                        name="num3"
                        value={num3}
                        maxLength={1}
                        onInput={handleInput}
                        onChange={handleChange}
                        tabIndex="3"
                        onKeyUp={inputfocus}
                        // onKeyPress={keyPressed}
                        className="form-control"
                      />
                    </li>
                    <li>
                      <input
                        type="number"
                        name="num4"
                        value={num4}
                        maxLength={1}
                        onInput={handleInput}
                        onChange={handleChange}
                        tabIndex="4"
                        onKeyUp={inputfocus}
                        // onKeyPress={keyPressed}
                        className="form-control"
                      />
                    </li>
                    <li>
                      <input
                        type="number"
                        name="num5"
                        value={num5}
                        maxLength={1}
                        onInput={handleInput}
                        onChange={handleChange}
                        tabIndex="5"
                        onKeyUp={inputfocus}
                        // onKeyPress={keyPressed}
                        className="form-control"
                      />
                    </li>
                    <li>
                      <input
                        type="number"
                        name="num6"
                        value={num6}
                        maxLength={1}
                        onInput={handleInput}
                        onChange={handleChange}
                        tabIndex="6"
                        onKeyUp={inputfocus}
                        // onKeyPress={keyPressed}
                        className="form-control"
                      />
                    </li>
                  </ul>
                </div>
                {apiErrors.message ? (
                  <span className="text-danger" style={{ fontSize: "14px" }}>
                    {apiErrors.message}
                  </span>
                ) : (
                  ""
                )}
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row ">
            <div className="col-md-12 col-12 mb-3">
              {seconds > 0 ? (
                <p className="Didntrecieve">
                  Time Remaining:{seconds < 10 ? `0${seconds}` : seconds}
                </p>
              ) : (
                <p className="Didntrecieve">Didn't recieve code?</p>
              )}
            </div>
            <div className="col-md-4 col-4 ">
              <p>
                <a href="#">
                  <Button
                    className={`${
                      seconds > 0 ? "btn-disable" : "btn-primary"
                    } btn `}
                    onClick={handleResendOTP}
                    disabled={seconds > 0}
                  >
                    Resend
                  </Button>
                </a>
              </p>
            </div>

            <div className="col-md-4 col-4 ">
              <Button
                variant="secondary"
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
            </div>

            <div className="col-md-4 col-4 ">
              <Button variant="primary" onClick={(e) => handleSubmitOtp(e)}>
                Verify
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VerifiOTP;
