export const validationMessages = {
    deleteConfirm: "Are you sure you want to delete this item?",
    changeStatus: "Are you sure you want to do this?",
    require: "This field is required",
    blogDescriptionMax: "Description is Too Long ( Allow Max 80 Characters )",
    unableToLogin: "Unable to login. Please try after some time.",
    image: `File is required`,
    name: `Name is required`,
    nameMax: `Name is too long ( Maximum 100 characters )`,
    NoFcopies: `Copies are Required`,
    categories: `Categories are required`,
    price: `Price is required`,
    royalty: `Royalty is required`,
    coverimage: `Cover image is required`,
    descriptionMax: "Description is too long ( Maximum 1000 characters )",
    descriptionLow: "Description is required",
    fName: `First name is required. `,
    lName: `Last name is required. `,
    allowAlphabets: `This field allow alphabets only.`,
    emailReq: `Email is required.`,
    validEmail: `Enter valid Email`,
    passwReq: `Password is required`,
    validPass: `Password must included atleast`,
    cpasswReq: `Confirm Password is required`,
    currentasswReq: `Current Password is required`,
    notMatchPassw: `Password and Confirm Password should be same`,
    phoneReq: `Contact number is required`,
    validMobile: `Please enter a valid mobile number.`,
    reqEnquiryType: "Enquiry type is required.",
    reqQueryMessage: "Query message is required.",
    allowMax8: "Allow max 8 characters.",
    Time_from: "From time is required",
    royality: `Royality should be less Than %s or min 1`,
    otpEnter: "Please enter OTP.",
    validOtp: `Please enter a valid OTP.`,
    invalTime: "Please enter a valid time.",
    sameTime: "To time should be greater than Form time.",
    reConnect: `Please disconnect other wallet`,
    greaterprice: `Price should be greater than or equal to 0.01`,
    selectModule: "Please select module",
    country: "Country is required",
    province: "Province is required",
    city: "City is required",
    streetNumber: "Street Number is required",
    streetName: "Street Name is required",
    postalCode: "Postal Code is required",
    dob: "Date of Birth is required",
    pep: "This field is required",
    married: "This field is required",
    personal_Depandants: "This field is required",
    occupation: "Occupation is required",
    employment_name: "Employment Name is required",
    employer_street_name: "Street name is required",
    employer_suite_number: "Suite Number is required",
    employer_country: "Country is required",
    employer_city: "City is required",
    employer_provinance: "State is required",
    total_Income: "Total Income is required",
    net_Worth: "Net Worth is required",
    net_Financial_Assests: "Net Finnancial Assests is required",
    jurisdiction: "Jurisdiction is required",
    investment_Knowledge: "Investment Knowledge is required",
    risk_Tolerance: "Risk Tolerance is required",
    investment_Time_Horizon: "Investement Time Horizon is required",
    investment_Objectives: "Investment Objectives is required",
    total_Amount_Invested: "Total Amount is required",
    address1: "Address is required",
    high_Risk_Investment: "This Field is Required",
    investing_Entirely: "This Field is Required",
    equivesta: "This Field is Required",
    investment_Quickly: "This Field is Required",
    info_Investment: "This Field is Required",
    ontario_Securities_Commision: "This Field is Required",
    first_name: "This Field is Required",
    investorString: "This Field is Required",
    orgType: "This Field is required",
  };
  const apiURl = {
    getLink: "/v1/links",
    createLogin: "/v1/common/otherLogin",
    resetMail: "/v1/user/mail/reset",
    resetPassword: "/v1/user/password/reset",
    getLinkedInProfile: "/linkedinLogin",
    getToken: "/v1/common/linkedin/token",
    getCollection: "/v1/projects",
    updateUser: "/v1/user",
    createUpdates: "/v1/updates",
    getUpdatesById: "/v1/updates",
    updatesId: "/editUpdates",
    shareRegister: "/v1/tokens/shareRegister",
    getActiveCampaign: "/v1/referral/campaign/getActive",
  };
  
  export default apiURl;
  